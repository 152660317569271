import './style.css';

const LogoSVG = (props) => {
    return (
        <svg
            id="logo-svg"
            data-name="logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 599.75 168.42"
            {...props}>
            <path className="cls-3"
                  d="m164.23,114.62c-24.65,62.36-107.93,72.84-146.28,17.64-9.63-14.28-14.84-31.62-14.22-48.85C6.33,15.78,84.8-19.31,136.48,24.59,86.62-16.02,11.89,16,8.25,80.45c-2.46,51.89,46.66,91.46,96.71,77.32,22.81-6.42,41.99-24.46,49.91-46.64,0,0,9.37,3.5,9.37,3.5h0Z"/>
            <path className="cls-3"
                  d="m162.68,64.31c14.49-46.89,65.6-71.77,111.17-53.29,59.48,24.31,69.54,104.18,17.33,141.95-27.5,20.04-67.71,19.61-94.58-1.19,27.09,19.85,66.47,19.54,93.1-.83,49.4-37.61,37.81-114.48-21.11-134.7-23.74-7.9-50.96-3.57-70.49,12.24-12.46,9.77-21.57,23.58-25.82,38.63,0,0-9.6-2.8-9.6-2.8h0Z"/>
            <path className="cls-1"
                  d="m187.33,95.54c-3.79,12.7-17.15,19.93-29.86,16.14-30.15-10.31-17.15-53.88,13.71-46,12.7,3.79,19.93,17.15,16.14,29.86h0Z"/>
            <path className="cls-2"
                  d="m182.61,94.13c-3.01,10.09-13.63,15.84-23.73,12.83-23.96-8.19-13.63-42.82,10.9-36.56,10.09,3.01,15.84,13.63,12.83,23.73h0Z"/>
            <g>
                <path className="cls-3"
                      d="m364.91,102.69h-12.02v20.71h-6.79v-51.19h16.95c5.77,0,10.2,1.31,13.31,3.94,3.11,2.62,4.66,6.45,4.66,11.46,0,3.19-.86,5.96-2.58,8.33-1.72,2.37-4.12,4.14-7.19,5.31l12.02,21.73v.42h-7.24l-11.11-20.71Zm-12.02-5.52h10.37c3.35,0,6.02-.87,8-2.61s2.97-4.06,2.97-6.97c0-3.17-.94-5.6-2.83-7.29s-4.61-2.55-8.17-2.57h-10.34v19.44Z"/>
                <path className="cls-3"
                      d="m429.85,99.46c0,5.02-.84,9.39-2.53,13.13-1.69,3.74-4.08,6.59-7.17,8.56s-6.7,2.95-10.83,2.95-7.61-.99-10.72-2.97c-3.12-1.98-5.54-4.8-7.26-8.47-1.72-3.67-2.61-7.92-2.65-12.74v-3.69c0-4.92.86-9.27,2.57-13.04,1.71-3.77,4.13-6.66,7.26-8.67s6.71-3.01,10.74-3.01,7.72.99,10.85,2.97c3.13,1.98,5.54,4.85,7.22,8.61s2.53,8.14,2.53,13.13v3.23Zm-6.71-3.3c0-6.07-1.22-10.73-3.66-13.97-2.44-3.25-5.85-4.87-10.23-4.87s-7.62,1.62-10.07,4.87c-2.45,3.25-3.71,7.75-3.78,13.52v3.76c0,5.88,1.24,10.51,3.71,13.87,2.47,3.36,5.88,5.04,10.21,5.04s7.73-1.59,10.12-4.76c2.39-3.18,3.62-7.73,3.69-13.66v-3.8Z"/>
                <path className="cls-3"
                      d="m479.31,123.4h-6.79l-25.77-39.45v39.45h-6.79v-51.19h6.79l25.84,39.62v-39.62h6.71v51.19Z"/>
                <path className="cls-3" d="m498.54,123.4h-6.75v-51.19h6.75v51.19Z"/>
                <path className="cls-3" d="m546.25,77.73h-16.45v45.67h-6.71v-45.67h-16.42v-5.52h39.59v5.52Z"/>
                <path className="cls-3"
                      d="m570.01,97.91l13.36-25.7h7.66l-17.65,32.1v19.09h-6.75v-19.09l-17.65-32.1h7.73l13.29,25.7Z"/>
            </g>
            <path className="cls-3"
                  d="m227.38,46.94c1.2,13.15,1.42,26.89,1.5,40.11-.07,13.23-.29,26.95-1.5,40.11-1.21-13.15-1.43-26.89-1.5-40.11.08-13.23.3-26.95,1.5-40.11h0Z"/>
        </svg>
    )
}

export default LogoSVG;