const CancelSVG = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             {...props}
             fill="currentColor"
             viewBox="0 0 16 16">
            <title>{props.title}</title>
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
            <path
                d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0z"/>
        </svg>
    )
}

export default CancelSVG;